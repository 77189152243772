<template>
  <div
    v-if="offers.length"
    class="offer-slider-container"
  >
    <ClientOnly>
      <Carousel
        ref="carousel"
        v-bind="carouselProps"
        @slide-start="(data) => changeSlide(data as SlideStartEvent)"
        @slide-end="afterSlide"
      >
        <Slide
          v-for="(offer, index) in offers"
          :key="offer.route"
          class="slide"
        >
          <OfferItem
            :offer="offer"
            data-aos="fade"
            :data-aos-duration="600 * index + 600"
            :dragging="dragging"
          />
        </Slide>
        <template #addons>
          <div
            class="custom-arrow slick-arrow slick-prev"
            :class="{ disabled: isFirstSlide }"
            @click="prev"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23.64 14.973"
            >
              <path
                d="M23.64 7.487a.915.915 0 0 0-.222-.542l-6.3-6.7A.789.789 0 0 0 16 .215a.812.812 0 0 0-.031 1.114l5.06 5.371H.788a.788.788 0 0 0 0 1.576h20.241l-5.061 5.369A.825.825 0 0 0 16 14.758a.789.789 0 0 0 1.114-.031l6.3-6.7a.7.7 0 0 0 .222-.542Z"
              />
            </svg>
          </div>
          <div
            class="custom-arrow slick-arrow slick-next"
            :class="{ disabled: isLastSlide }"
            @click="next"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23.64 14.973"
            >
              <path
                d="M23.64 7.487a.915.915 0 0 0-.222-.542l-6.3-6.7A.789.789 0 0 0 16 .215a.812.812 0 0 0-.031 1.114l5.06 5.371H.788a.788.788 0 0 0 0 1.576h20.241l-5.061 5.369A.825.825 0 0 0 16 14.758a.789.789 0 0 0 1.114-.031l6.3-6.7a.7.7 0 0 0 .222-.542Z"
              />
            </svg>
          </div>
        </template>
      </Carousel>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { Carousel, Slide } from 'vue3-carousel'
import type {
  CarouselInstance,
  CarouselProps,
  CustomOffer,
  Offer,
  SlideStartEvent,
  WithRoute,
} from '~/types'

withDefaults(
  defineProps<{
    offers?: WithRoute<CustomOffer>[] | WithRoute<Offer>[]
  }>(),
  {
    offers: () => [],
  },
)

const emit = defineEmits<{
  changeSlide: [slideNew: number]
}>()

const carousel = ref<CarouselInstance>(null)

const { width } = useElementSize(carousel)

const dragging = ref(false)

const carouselProps = computed(
  () =>
    ({
      touchDrag: true,
      transition: 500,
      snapAlign: 'start',
      itemsToShow: width.value / 1080,
    }) satisfies CarouselProps,
)

const carouselData = computed(() => carousel.value?.data)

const isFirstSlide = computed(
  () => carouselData.value?.currentSlide.value === 0,
)

const isLastSlide = computed(
  () =>
    carouselData.value?.currentSlide.value ===
    carouselData.value?.maxSlide.value,
)

const changeSlide = ({ slidingToIndex }: SlideStartEvent) => {
  dragging.value = true
  emit('changeSlide', slidingToIndex)
}

const afterSlide = () => {
  dragging.value = false
}

const prev = () => {
  carousel.value?.prev()
}

const next = () => {
  carousel.value?.next()
}
</script>

<style lang="scss">
.offer-slider-container {
  width: 100%;

  .carousel__track {
    display: flex;
    height: 100%;
  }

  .carousel__slide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    margin-right: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .custom-arrow {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 100%;
    padding: 0;
    font-size: 0;
    line-height: 0;
    background: $white;

    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    border: none;
    cursor: pointer;
    transform: translateX(100%);

    svg {
      width: 2.36rem;
    }

    &::before {
      content: '';
    }

    &.slick-prev {
      right: auto;
      left: 0;
      transform: translateX(-100%);

      svg {
        transform: rotate(180deg);
      }
    }

    &.disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 700px) {
  .offer-slider-container {
    .carousel__slide {
      padding-left: 2.5rem;
    }

    .custom-arrow {
      display: none;
    }
  }
}
</style>
